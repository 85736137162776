/**
 * Created by gavinhe on 16/9/17.
 */

const BACKSPACE = 8
const DELETE = 46

export const unmask = (value) => value.replace(/[^\d]/g, '')

const getKeyCode = (e) => e.which || e.keyCode || e.charCode || e.key

const getIsBackspace = (keyCode) => keyCode === BACKSPACE

const getIsDelete = (keyCode) => keyCode === DELETE

const applyFormatMask = (string, mask, isBackspace) => {
  let formattedString = ''
  let numberPos = 0

  for (let j = 0; j < mask.length; j += 1) {
    const currentMaskChar = mask[j]
    if (currentMaskChar === 'X') {
      const digit = string.charAt(numberPos)
      if (!digit) {
        break
      }
      formattedString += string.charAt(numberPos)
      numberPos += 1
    } else if (string.charAt(numberPos) || (currentMaskChar === '/' && !isBackspace)) {
      // Inserts '/' if not a backspace
      formattedString += currentMaskChar
    }
  }
  return formattedString
}

/**
 * @param position
 * @param mask
 * @returns {Number} length of separator
 * eg. position 14 with mask XXXX XXXXX XXXXX
 * separator would be 2 x ' ', length would be 2
 * eg. position 6 with mask XX / XX
 * separator would be ' / ', length would be 3
 * Genius --> @_@ :)
 */
const checkSeparator = (position, mask) => {
  const subStr = mask.substring(0, position)
  const separatorLength = subStr.replace(/X/g, '').length
  return separatorLength
}

const makeCardMask = (getMask, regex) => {
  let keyCode = ''
  let oldValue = ''
  let oldCursor = ''

  const keydownHandler = (e) => {
    const element = e.target
    keyCode = getKeyCode(e)
    oldValue = element.value
    oldCursor = element.selectionEnd || 0
  }

  const inputHandler = (event, target, value) => {
    let newCursorPosition
    let newValue = unmask(value)
    if (newValue.match(regex)) {
      const mask = getMask(newValue)

      newValue = applyFormatMask(newValue, mask, getIsBackspace(keyCode))
      // Here is the magic calculation for newCursorPosition
      newCursorPosition =
        oldCursor -
        checkSeparator(oldCursor, mask) +
        checkSeparator(oldCursor + (newValue.length - oldValue.length), mask) +
        (unmask(newValue).length - unmask(oldValue).length)

      if (getIsDelete(keyCode)) {
        newCursorPosition = oldCursor
      }

      if (newValue === oldValue) {
        if (getIsBackspace(keyCode)) {
          newCursorPosition -= 1
        }
        if (getIsDelete(keyCode)) {
          newCursorPosition += 1
        }
      }

      return { maskedValue: newValue, newCursorPosition }
    }
    newCursorPosition = oldCursor
    return { maskedValue: oldValue, newCursorPosition }
  }

  return {
    keydownHandler,
    inputHandler,
  }
}

export const cardExpressions = {
  visa: /^4[0-9]+/,
  mastercard: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[0-1]|2720)[0-9]+/,
  amex: /^3[47][0-9]+/,
}

const cardMasks = {
  visa: 'XXXX XXXX XXXX XXXX',
  mastercard: 'XXXX XXXX XXXX XXXX',
  amex: 'XXXX XXXXXX XXXXX',
}

const getCardMask = (cardType) => cardMasks[cardType] || 'XXXX XXXX XXXX XXXX'

export const getCardType = (number) => {
  const matchingCards = Object.keys(cardExpressions).filter((key) =>
    cardExpressions[key].test(number)
  )

  if (matchingCards.length === 1) {
    return matchingCards[0]
  }
  return ''
}

export const makeCardNumberMask = () =>
  makeCardMask((val) => getCardMask(getCardType(val)), new RegExp(/^\d{0,16}$/g))
export const makeGenericMask = (regex, mask) => makeCardMask(() => mask, regex)

const getCurrentYearFirstTwoDigits = () => {
  const today = new Date()
  const currentYearFirstTwoDigit = `${today.getFullYear()}`.substring(0, 2)
  return currentYearFirstTwoDigit
}

/**
 * @param expiryValue eg. 11 / 18
 * @returns {{month: string, year: string}}
 * split 12 / 18 into 12 2018
 */
export const splitMonthYearFromExpiry = (expiryValue) => {
  const expiry = unmask(expiryValue)
  let month = expiry.substring(0, 2)
  if (month[0] === '0') {
    ;[, month] = month
  }
  let year = `${getCurrentYearFirstTwoDigits()}${expiry.substring(2, 4)}`
  if (expiry.length === 6) {
    year = expiry.substring(2, 6)
  }
  return { month, year }
}
