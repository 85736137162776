/**
 * utils.js
 *
 * loaded on all pages.
 *
 * All other dependencies should be added in the module definition.
 * public vars and methods should be added to the Base function, which is returned and loaded in to the global k3 object
 *
 */

import { cardExpressions } from 'base/cardMask'

if (typeof window !== 'undefined') {
  window.onbeforeunload = () => {
    window.unload = true
  }
}

/**
 * Super quick way to empty a DOM node.
 * @param  {Element} node DOM Element
 * @return {Element}
 */
export const emptyNode = (node) => {
  if (!node) return node
  let i = (node.childNodes && node.childNodes.length) || 0
  while (i > 0) {
    node.removeChild(node.lastChild)
    i = (node.childNodes && node.childNodes.length) || 0
  }
  return node
}

/**
 * Cross-browser method to *set* innerText.
 * @return {[type]}
 */
export const innerText = (node, text) => {
  emptyNode(node).appendChild(document.createTextNode(text))
  return node
}

if (typeof window !== 'undefined') {
  window.requestAnimFrame = (function requestAnimFrame() {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      ((callback) => {
        window.setTimeout(callback, 1000 / 60)
      })
    )
  })()
}

export const isElementVisible = (target) => {
  const rect = target.getBoundingClientRect()
  return (
    (rect.top >= 0 || rect.bottom >= 0) &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

/**
 * Animate a scroll to the designated element
 * @param  {HtmlElement} target jQ selector string for the element
 * @param  {Number} time   animation length in ms
 * @param  {Number} offset number of pixels to offset from target element
 * @return {Void}
 */
export const scrollTo = (target, speed = 2000, offset = 0) => {
  let targetEl
  const bodyOffset = document.body.getBoundingClientRect().top
  if (typeof target === 'string') {
    targetEl = document.querySelector(target)
  } else {
    targetEl = target
  }

  if (!targetEl) {
    return
  }

  const top = targetEl.getBoundingClientRect
    ? targetEl.getBoundingClientRect().top
    : targetEl.position().top
  const scrollTargetY = top - bodyOffset - (offset || 0)

  const { scrollY } = window
  let currentTime = 0

  // min time .1, max time .8 seconds
  const time = Math.max(0.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8))

  // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
  function easeOutSine(pos) {
    return Math.sin(pos * (Math.PI / 2))
  }

  // add animation loop
  function tick() {
    currentTime += 1 / 60

    const p = currentTime / time
    const t = easeOutSine(p)

    if (p < 1) {
      window.requestAnimFrame(tick)

      window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t)
    } else {
      window.scrollTo(0, scrollTargetY)
    }
  }

  // call it once to get started
  tick()
}

export const getYouTubeIdFromUrl = (url) => {
  let id = url.split('v=')[1]
  if (!id) return url
  const ampersand = id.indexOf('&')
  if (ampersand !== -1) id = id.substring(0, ampersand)
  return id
}

export const queryStringToObject = (initialQuerystring) => {
  let querystring = initialQuerystring
  const qi = querystring.indexOf('?')
  if (qi !== -1) querystring = querystring.slice(qi + 1)
  const paramsArray = querystring.split('&')
  const paramsObject = {}
  paramsArray.forEach((pair) => {
    if (pair.includes('=')) {
      const keyvalue = pair.split('=')
      const key = decodeURIComponent(keyvalue[0].replace(/\+/g, ' '))
      const value = decodeURIComponent(keyvalue[1].replace(/\+/g, ' '))
      paramsObject[key] = value
    }
  })
  return paramsObject
}

export const objectToQueryString = (_object) => {
  let querystring = ''
  const keys = Object.keys(_object)
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i]
    if (Object.prototype.hasOwnProperty.call(_object, key)) {
      const value = _object[key] || ''
      querystring += `${key}=${value}&`
    }
  }
  return querystring.slice(0, -1)
}

export const moneyFmt = (initialnStr, dp = 2, initialSymbol) => {
  let symbol = initialSymbol
  if (typeof initialSymbol === 'undefined') {
    symbol = (typeof window !== 'undefined' && k3 && k3.store.symbol) || '$'
  }
  let nStr = initialnStr
  nStr = Number(nStr).toFixed(dp)
  nStr += ''
  const x = nStr.split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? `.${x[1]}` : ''
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1,$2')
  }
  if (x1 < 0) {
    x1 *= -1
    return `-${symbol}${x1}${x2}`
  }

  return `${symbol}${x1}${x2}`
}

export const formatDate = (text) => {
  // Server-side nodejs screws up en-GB formatting so we have to force the ordering
  const dateText = text.includes('T') ? text.split('T')[0] : text
  const date = new Date(dateText)
  const monthAndYear = date.toLocaleString('en-GB', { month: 'long', year: 'numeric' })
  const day = date.toLocaleString('en-GB', { day: 'numeric' })
  return `${day} ${monthAndYear}`
}

export const cleanFmt = (nStr) => {
  const symbol = (k3 && k3.store.symbol) || '$'
  let num = Number(nStr)
  // check if it's a whole number before hiding the mantissa
  num = num % 1 === 0 ? num.toFixed(0) : num.toFixed(2)

  return symbol + num
}

export const numFormat = (num) => {
  let numString = num.toString()
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(numString)) {
    numString = numString.replace(rgx, '$1,$2')
  }
  return numString
}

/**
 * Call .setProgress() on a laddaButton to simulate an in-progress
 * @param  {Ladda instance} laddaButton
 * @param  {Number} intms       interval timeout in ms
 * @return {Object}             interval
 */
export const laddaProgress = (laddaButton, intms = 250) => {
  let progress = 0
  const interval = setInterval(() => {
    const random = Math.random() * 0.1
    progress = Math.min(progress + random, 1)
    laddaButton.setProgress(progress)
    if (progress >= 1) clearInterval(interval)
  }, intms)
  return interval
}

/**
 * Call .setProgress() on a laddaButton to simulate an in-progress,
 * but it will stop at a certain fixed progress.
 * @param {Ladda instance} laddaButton
 * @param {Number} intms    interval timeout in ms
 * @param {Number}          percentage of process
 *
 */
export const laddaProgressFixed = (laddaButton, intms = 250, initialFixedProgress) => {
  let fixedProgress = initialFixedProgress
  if (fixedProgress >= 1) fixedProgress = 1
  let progress = 0
  const interval = setInterval(() => {
    const random = Math.random() * 0.05
    progress = Math.min(progress + random, fixedProgress)
    laddaButton.setProgress(progress)
    if (progress >= fixedProgress) clearInterval(interval)
  }, intms)
  return interval
}

/**
 * Puts the key-values of a form element into an object
 * I swear there must be a native jQuery method for doing this because
 * it seems so obvious, but I can't find it anywhere.
 * @param  {String/Node} form    selector or DOM node
 * @return {Object}
 */
export const mapForm = (form) =>
  Array.from(form.elements).reduce((memo, obj) => {
    if (!obj.name || (obj.type === 'checkbox' && obj.checked === false)) {
      // If checkbox, skip if "checked" is false
      return memo
    }

    return {
      ...memo,
      [obj.name]: obj.value,
    }
  }, {})

/**
 * URL/Form encode an object to be sent with fetch API.
 *
 * @param {object} data
 * @returns {string}
 */
export const formEncode = (data) =>
  Object.keys(data)
    .map((k) => `${encodeURIComponent(k)}=${data[k] ? encodeURIComponent(data[k]) : ''}`)
    .join('&')

/**
 * Selects a text element on the document.
 * Works on any text node (eg. span, div)
 *
 * @param element   A DOM node to select the text of
 */
export const selectText = (element) => {
  if (window.getSelection && document.createRange) {
    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(element)
    selection.removeAllRanges()
    selection.addRange(range)
  } else if (document.selection && document.body.createTextRange) {
    const range = document.body.createTextRange()
    range.moveToElementText(element)
    range.select()
  }
}

export const detectCardType = (number) => {
  const cleaned = number.split(/[ -]/).join('')
  const re = {
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5]\d{14}|^(222\d|22[3-9]\d|2[3-6]\d{2}|27[0-1]\d|2720)\d{12}$/,
    amex: /^3[47][0-9]{13}$/,

    // electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    // maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    // dankort: /^(5019)\d+$/,
    // interpayment: /^(636)\d+$/,
    // unionpay: /^(62|88)\d+$/,
    // diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    // discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    // jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
  }

  const keys = Object.keys(re)
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i]
    if (re[key].test(cleaned)) {
      return key
    }
  }
  return ''
}

export const detectCardTypePartial = (partial) => {
  const cleaned = partial.split(/[ -]/).join('')
  const keys = Object.keys(cardExpressions)

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i]
    if (cardExpressions[key].test(cleaned)) {
      return key
    }
  }
  return ''
}

export const observeDOM = (obj, callback) => {
  const MutationObserver = window.MutationObserver || window.WebKitMutationObserver
  const eventListenerSupported = window.addEventListener

  if (MutationObserver) {
    // define a new observer
    const obs = new MutationObserver((mutations) => {
      if (mutations[0].addedNodes.length || mutations[0].removedNodes.length) {
        callback()
      }
    })
    // have the observer observe node for changes in children
    obs.observe(obj, { childList: true, subtree: true })
  } else if (eventListenerSupported) {
    obj.addEventListener('DOMNodeInserted', callback, false)
    obj.addEventListener('DOMNodeRemoved', callback, false)
  }
}

/* Useful as an sorting function comparator */
export const alphaNumStringComparator = (stra, strb) => {
  // Uses JS built in comparison function localeCompare()
  // Removes alphanumeric to make it consistent with IE
  const alphaStra = stra.replace(/[^A-Za-z0-9]/g, '')
  const alphaStrb = strb.replace(/[^A-Za-z0-9]/g, '')

  return alphaStra.localeCompare(alphaStrb)
}

export const convertToBytes = (value, unit) => {
  switch (unit.toLowerCase()) {
    case 'tb':
      return value * 10 ** 12
    case 'gb':
      return value * 10 ** 9
    case 'mb':
      return value * 10 ** 6
    case 'kb':
      return value * 10 ** 3
    case 'b':
    default:
      return value
  }
}

export const poll = (fn, timeout = 2000, interval = 100) => {
  const endTime = Number(new Date()) + timeout

  const checkCondition = (resolve, reject) => {
    // If the condition is met, we're done!
    const result = fn()
    if (result) {
      resolve(result)
    } else if (Number(new Date()) < endTime || timeout === 0) {
      // If the condition isn't met but the timeout hasn't elapsed, go again
      setTimeout(checkCondition, interval, resolve, reject)
    } else {
      // Didn't match and too much time, reject!
      reject(new Error(`timed out for ${fn}`))
    }
  }

  return new Promise(checkCondition)
}

// https://github.com/JedWatson/exenv/blob/master/index.js
// Returns true if access to DOM is available
// eg is in the browser
export const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

// https://stackoverflow.com/a/7124052/10372876
export const htmlEscape = (str) =>
  str
    .replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')

export const htmlUnescape = (str) =>
  str
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')

// taken from https://github.com/mrded/is-url-external/blob/master/src/index.ts, modified to suit
const getHostFromUrl = (url) => {
  try {
    return new URL(url, window.location).hostname.replace('www.', '')
  } catch (e) {
    return undefined
  }
}

export const isExternalLink = (url) => {
  const providedHost = getHostFromUrl(url)
  if (providedHost === undefined) return true
  return providedHost !== getHostFromUrl(window.location.origin)
}

export const safelyRedirect = (href) => {
  window.location.assign(isExternalLink(href) ? `/` : href)
}

export const browserWidth = () =>
  Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  )

export const browserHeight = () =>
  Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight,
    window.innerHeight
  )

// https://stackoverflow.com/a/24457420/10382825
// Check if the string has only positive digits - Used for inComm Gift Card PIN Verification
export function hasOnlyPositiveDigits(value) {
  return /^\d+$/.test(value)
}

export const setVhRootVariable = () => {
  // Get inner window height and set is as css custom variable
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

export default {
  emptyNode,
  innerText,
  isElementVisible,
  scrollTo,
  getYouTubeIdFromUrl,
  queryStringToObject,
  objectToQueryString,
  moneyFmt,
  formatDate,
  cleanFmt,
  numFormat,
  laddaProgress,
  laddaProgressFixed,
  mapForm,
  formEncode,
  selectText,
  detectCardType,
  detectCardTypePartial,
  observeDOM,
  alphaNumStringComparator,
  convertToBytes,
  poll,
  canUseDOM,
  htmlEscape,
  htmlUnescape,
  isExternalLink,
  browserWidth,
  browserHeight,
  hasOnlyPositiveDigits,
}
